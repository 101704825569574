import gql from 'graphql-tag'

export const GET_SEARCH_ITEM = gql`
  {
    searchItem @client
  }
`
export const GET_CURRENT_USER = gql`
  {
    currentUser @client
  }
`
