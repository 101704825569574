import gql from "graphql-tag"

const typeDefs = gql`
  type Mutation {
    updateSearchItem(searchItem: String!): String!
    updateUser(user: User!): User!
  }
  type User {
    usergroups: [userGroups]!
    username: String!
  }
  type userGroups {
    groupname: String!
    precedence: Int!
  }
`

export default typeDefs
