import { abilitiesPlugin } from '@casl/vue';
import { Ability } from '@casl/ability';
import Vue from 'vue';

export const ability = new Ability();

export default function () {
  Vue.prototype.$ability = ability;
  Vue.use(abilitiesPlugin, ability);
}
