const aws = {
  region: "eu-west-1",
  identityPoolId: "eu-west-1:cd4fa1f9-5965-4e9b-8a32-a6e73a05cb3b",
  userPoolId: "eu-west-1_FjEtNwKAQ",
  userPoolClientId: "3su067b2kt7cj6k52ejj43hq3p",
  userManagementAppsyncEndpoint: "https://zwb7l3m4nzhfvp2vlejcrqnqeu.appsync-api.eu-west-1.amazonaws.com/graphql",
  stockManagementAppsyncEndpoint: "https://7g5cpdpn65dbxlucb6rnqrurt4.appsync-api.eu-west-1.amazonaws.com/graphql",
  productionManagementAppsyncEndpoint: "https://6tgqotg5pzgcxjyj7xtq3aa3mq.appsync-api.eu-west-1.amazonaws.com/graphql"
}
export default aws
