import { VueConstructor } from 'vue';
import VueApollo from 'vue-apollo';
import apolloUserManagement from '../gql/apollo-user-management';
import apolloStockManagament from '../gql/apollo-stock-management';
import apolloProductionManagement from '../gql/apollo-production-management';

const appsyncProvider = new VueApollo({
  clients: {
    apolloUserManagement,
    apolloProductionManagement,
    apolloStockManagament
  },
  defaultClient: apolloUserManagement
});

export default async ({ app, Vue }: { app: any; Vue: VueConstructor }) => {
  Vue.use(VueApollo);
  app.apolloProvider = appsyncProvider;
};
