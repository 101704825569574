export default {
  submit: 'Submit',
  cancel: 'Cancel',
  close: 'Close',
  footer: 'Copyright © Synadia. All Rights Reserved',
  welcome: 'Welcome',
  username: 'Username',
  confirm: 'Confirm',
  email: 'Email Address',
  name: 'Full Name',
  alert: 'Alert',
  success: 'Success',
  description: 'Description',
  no_internet: 'No Internet connection. Try again?',
  location: 'Location',
  total: 'Total',
  error: 'Error',
  general: {
    switchTenant: 'Choose tenant',
    yes: 'Yes',
    no: 'No',
    filter: {
      addNewFilter: '+ add new filter',
      and: 'And',
      column: 'Column',
      condition: 'Condition',
      filter: 'Filter',
      title: 'Search filter',
      value: 'Value',
      where: 'Where'
    },
    options: {
      userDetails: 'Current user: ',
      switchLanguage: 'Switch language',
      dutch: 'Dutch',
      english: 'English',
      changePassword: 'Change password',
      signout: 'Sign out'
    },
    changePassword: {
      title: 'Change password',
      cancel: 'Cancel',
      save: 'Save',
      success: 'Your password is successfully changed.',
      failed:
        'Something went wrong whilst changing your password, please try again.',
      failNewPassword:
        'The new passwords are not the same, please check and try again.',
      inputs: {
        oldPassword: 'Old password',
        newPassword: 'New password',
        newPasswordCheck: 'Repeat new password'
      }
    }
  },
  production: {
    loadMore: 'LOAD MORE',
    title: 'Production management',
    search: 'Sales order',
    tabs: {
      orders: 'Orders',
      production: 'Current production (Orders)',
      batches: 'Batches'
    },
    assemblies: {
      table: {
        springBatchId: 'Spring batch ID',
        partId: 'Part ID',
        salesOrder: 'Sales order',
        partNumber: 'Part number',
        stationId: 'Station ID',
        state: 'State',
        materialAvailable: 'Material availble',
        createdAt: 'created at',
        updatedAt: 'updated at'
      }
    },
    batches: {
      table: {
        batch: 'Spring batch ID',
        state: 'State',
        material: 'Material available',
        productionWeek: 'Production Week',
        productionDate: 'Production Date',
        actions: 'Actions'
      },
      releaseBatch: {
        success: 'The batch release process is successfully started',
        failed:
          'Something went wrong while releasing the batch, please try again'
      }
    },
    errors: {
      title: 'Batch errors',
      table: {
        orderId: 'OrderId',
        partId: 'PartId',
        description: 'Description'
      }
    },
    orders: {
      cancel: {
        title: 'Warning',
        description: 'Are you sure to cancel the order?',
        success: 'The order cancel process is successfully started',
        failed:
          'Something went wrong while cancelling the order, please try again'
      },
      table: {
        salesOrder: 'Sales order ID',
        state: 'State',
        material: 'Material available',
        productionWeek: 'Production Week',
        productionDate: 'Production Date',
        actions: 'Actions'
      }
    },
    productionOrders: {
      table: {
        salesOrder: 'Sales order ID',
        state: 'State',
        material: 'Material available',
        expectedWeek: 'Expected production week',
        actions: 'Actions'
      }
    }
  },
  stocks: {
    title: 'Stock management',
    exportBtn: 'Export to .CSV',
    loadMore: 'LOAD MORE',
    search: 'Article nr.',
    table: {
      actions: 'Actions',
      article: 'Article nr.',
      qtyMain: 'Qty. main',
      containers: 'Containers',
      qtyTemp: 'Qty. temporary',
      bundles: 'Bundles',
      qtyTotal: 'Qty. total',
      qtyReserved: 'Qty. reserved',
      qtyAvailable: 'Qty. available',
      qtyAssembliesReleased: 'Qty. assemblies released',
      lastMonth: 'Used last month',
      lastQuarter: 'Used last quarter',
      lastYear: 'Used last year',
      minThreshold: 'Min. threshold'
    },
    editContainerQty: {
      title: 'Edit container quantity',
      success:
        'The container quantity is placed in a que to update, this can take a couple of minutes.',
      failed:
        'Something went wrong while updating the container quantity, please try again.',
      btnCancel: 'Cancel',
      btnContinue: 'Continue',
      stepper: {
        step1: {
          title: 'Enter container id',
          input: 'Container ID',
          errorMessage:
            'The container id is incorrect or does not have a bundle connected to it.'
        },
        step2: {
          title: 'Edit container quantity',
          inputCurrent: 'Current container quantity',
          inputNew: 'New container quantity',
          inputNewHint:
            'It might take a couple of minutes for the changes to show.',
          btnBack: 'Back',
          btnFinish: 'Finish'
        }
      }
    },
    editTempQty: {
      title: 'Edit bundle quantity',
      success:
        'The bundle quantity is placed in a que to update, this can take a couple of minutes.',
      failed:
        'Something went wrong while updating the bundle quantity, please try again.',
      stepper: {
        step1: {
          title: 'Select bundle',
          input: 'Select bundle',
          errorMessage:
            'The bundle is not correct please check if you entered the correct one.',
          btnContinue: 'Continue'
        },
        step2: {
          title: 'Edit bundle quantity',
          inputCurrent: 'Current bundle quantity',
          inputNew: 'New bundle quantity',
          inputNewHint:
            'It might take a couple of minutes for the changes to show.',
          btnBack: 'Back',
          btnFinish: 'Finish'
        }
      }
    },
    editMinThreshold: {
      title: 'Edit minimun threshold',
      input: 'Minimum threshold',
      success: 'The minimum threshold is updated successfully.',
      failed:
        'Somethign went wrong while updating the minimum threshold, please try again.'
    }
  },
  containers: {
    title: 'Container management',
    createBtn: 'Create container',
    exportBtn: 'Export to .CSV',
    loadMore: 'LOAD MORE',
    search: 'Search',
    table: {
      containerId: 'Container ID',
      partId: 'Part ID',
      quantity: 'Quantity',
      state: 'State',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      actions: 'Actions'
    },
    create: {
      title: 'Create container',
      success: 'The container is successfully added.',
      failed:
        'Something went wrong while creating the container, please try again.',
      inputs: {
        containerId: 'Container ID'
      }
    },
    delete: {
      title: 'Delete container',
      description:
        'Are you sure you want to delete this container, the container will be deleted permanently.',
      success: 'The container is successfully deleted.',
      failed:
        'Something went wrong while deleteing the container, please try again.',
      delete: 'Delete'
    }
  },
  locations: {
    title: 'Locations management',
    loadMore: 'LOAD MORE',
    createBtn: 'Create location',
    search: 'Search',
    table: {
      locationId: 'Location ID',
      containerId: 'Container ID',
      column: 'Column',
      partId: 'Part ID',
      quantity: 'Quantity',
      rack: 'Rack',
      row: 'Row',
      state: 'State',
      type: 'Type',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      actions: 'Actions'
    },
    create: {
      title: 'Create location',
      success: 'The location is successfully added.',
      failed:
        'Something went wrong while creating the location, please try again.',
      inputs: {
        column: 'Column',
        rack: 'Rack',
        row: 'Row',
        type: 'Location type'
      }
    },
    delete: {
      title: 'Delete location',
      description:
        'Are you sure you want to delete this location, the location will be deleted permanently.',
      success: 'The location is successfully deleted.',
      failed:
        'Something went wrong while deleteing the location, please try again.',
      delete: 'Delete'
    }
  },
  parts: {
    title: 'Part management',
    loadMore: 'LOAD MORE',
    createBtn: 'Create part',
    searchError: 'Something went wrong while searching, please try again.',
    table: {
      partNumber: 'Part number',
      description: 'Description',
      diameter: 'Diameter',
      type: 'Type',
      length: 'Length',
      active: 'Active',
      state: 'State',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      actions: 'Actions'
    },
    create: {
      title: 'Create part',
      success: 'The part is successfully added.',
      failed: 'Something went wrong while creating the part, please try again.',
      inputs: {
        partNumber: 'Part number',
        component: 'Component',
        description: 'Description',
        length: 'Length',
        type: 'Type',
        diameter: 'Diameter'
      }
    },
    edit: {
      title: 'Edit part',
      success: 'The part is successfully updated.',
      failed: 'Something went wrong while updating the part, please try again.',
      inputs: {
        partNumber: 'Part number',
        component: 'Component',
        description: 'Description',
        length: 'Length',
        type: 'Type',
        state: 'State',
        diameter: 'Diameter'
      }
    }
  },
  tasks: {
    edit: {
      title: 'Edit task',
      cancel: 'Cancel',
      save: 'Save',
      success: 'Task successfully updated.',
      failed: 'Something went wrong while editing the task, please try again.',
      inputs: {
        prio: 'Priority'
      }
    },
    loadMore: 'LOAD MORE',
    title: 'Task management',
    search: 'Search',
    table: {
      actions: 'Actions',
      partNumber: 'Part Number',
      prio: 'Priority',
      type: 'Type',
      qty: 'Quantity',
      state: 'State',
      createdAt: 'Created at',
      completedAt: 'Completed at'
    }
  },
  users: {
    title: 'Users management',
    createBtn: 'Create user',
    loadMore: 'LOAD MORE',
    table: {
      username: 'Username',
      groups: 'Group(s)',
      actions: 'Actions',
      createdAt: 'Created at'
    },
    create: {
      title: 'Create user',
      cancel: 'Cancel',
      save: 'Save',
      success: 'User successfully created.',
      failed: 'Something went wrong while creating the user, please try again.',
      inputs: {
        email: 'Email address',
        name: 'Name',
        tenant: 'Tenant',
        groups: 'Group(s)'
      }
    },
    edit: {
      title: 'Edit user',
      cancel: 'Cancel',
      save: 'Save',
      success: 'User successfully updated.',
      failed: 'Something went wrong while editing the user, please try again.',
      inputs: {
        email: 'Email address',
        name: 'Name',
        tenant: 'Tenant',
        groups: 'Group(s)'
      }
    },
    disable: {
      success: 'User successfully disabled.',
      failed:
        'Something went wrong while disabling the user, please try again.',
      title: 'Disable user',
      description: 'Are you sure that you want to disable this user?',
      cancel: 'Cancel',
      delete: 'Disable'
    },
    enable: {
      success: 'User successfully enabled.',
      failed: 'Something went wrong while enabling the user, please try again.',
      title: 'Enable user',
      description: 'Are you sure that you want to enable this user?',
      cancel: 'Cancel',
      enable: 'Enable'
    },
    delete: {
      success: 'User successfully deleted.',
      failed: 'Something went wrong while deleting the user, please try again.',
      title: 'Delete user',
      description:
        'Are you sure that you want to delete this user? The user will be deleted permanently.',
      cancel: 'Cancel',
      delete: 'Delete'
    }
  },
  config: {
    title: 'Config',
    table: {
      parameter: 'Parameter',
      value: 'Value'
    },
    edit: {
      title: 'Edit parameter',
      cancel: 'Cancel',
      save: 'Save',
      success: 'Successfully updated.',
      failed: 'Something went wrong, please try again.',
      inputs: {
        parameter: 'Parameter',
        value: 'Value'
      }
    }
  },
  auth: {
    sign_in: 'Sign In',
    reset_pass: 'Reset password',
    back_login: 'Back to Log in',
    resend_code: 'Resend Code',
    new_pass: 'New Password',
    new_pass_repeat: 'Repeat new Password',
    confirm_pass: 'Confirm Password',
    confirm_code: 'Verification code',
    change_pass: 'Change Password',
    pass: 'Password',
    email_phone: 'Email or phone number',
    save_creds: 'Save Credentials'
    // code: "Code",
    // go_login: "Login with new account",
    // confirm: "Confirm Sign Up",
    // check_email: "Check your email for confirmation Code",
  },
  errors: {
    invalid_username_or_password: 'Invalid username or password',
    no_negative_numbers: 'The number must be bigger then 0',
    required: 'Field is required',
    to_long: 'Field is too long',
    to_short: 'Field is too short',
    whitespaces: 'Field contains only whitespaces',
    email: 'E-mail must be valid',
    pass_short: 'Password is too short',
    pass_number: 'Password should contain at least 1 number',
    pass_uppercase: 'Password should contain at least 1 uppercase letter',
    pass_lowercase: 'Password should contain at least 1 lowercase letter',
    code_mismatch: 'Provided code does not match what the server was expecting',
    code_expired: 'Code has expired',
    not_authorized: 'The user is not authorized',
    username_taken: 'This username already exists in the user pool'
  }
};
