/* eslint-disable */
import Amplify, * as AmplifyModules from 'aws-amplify';
import { VueConstructor } from 'vue';
import { AmplifyPlugin, AmplifyEventBus } from 'aws-amplify-vue';
import aws from '../aws-exports';

// TODO:remove for production
Amplify.configure({
  aws_project_region: aws.region,
  aws_cognito_identity_pool_id: aws.identityPoolId,
  aws_cognito_region: aws.region,
  aws_user_pools_id: aws.userPoolId,
  aws_user_pools_web_client_id: aws.userPoolClientId,
  oauth: {},
  Auth: {
    identityPoolId: aws.identityPoolId,
    region: aws.region,
    userPoolId: aws.userPoolId,
    userPoolWebClientId: aws.userPoolClientId,
  },
});

export default ({ Vue }: { Vue: VueConstructor }) => {
  Vue.use(AmplifyPlugin, AmplifyModules);
  Vue.prototype.$Amplify = Amplify;
  Vue.prototype.$AmplifyEventBus = AmplifyEventBus;
  Vue.prototype.$Auth = AmplifyModules.Auth;

  // TODO:move to separete boot file (e.g. common)
  Vue.prototype.$log = console.log;
};
