import { GET_SEARCH_ITEM, GET_CURRENT_USER } from 'src/gql/client/queries'

const resolvers = {
  Mutation: {
    updateSearchItem(_: any, { searchItem }: any, { cache }: any) {
      const data = cache.readQuery({ query: GET_SEARCH_ITEM })
      data.searchItem = searchItem
      cache.writeQuery({ query: GET_SEARCH_ITEM, data })
    },
    updateCurrentUser(_: any, { currentUser }: any, { cache }: any) {
      const data = cache.readQuery({ query: GET_CURRENT_USER })
      data.currentUser = currentUser
      cache.writeQuery({ query: GET_CURRENT_USER, data })
    }
  }
}

export default resolvers
