





import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from 'src/mixins/common-mixin';

@Component
export default class App extends Mixins(CommonMixin) {
  private configurationOptions: any = {
    signInConfig: {
      isSignUpDisplayed: false,
      usernameAttributes: 'email'
    }
  };
}
