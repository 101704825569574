import { InMemoryCache } from 'apollo-cache-inmemory'

const cache = new InMemoryCache()
cache.writeData({
  data: {
    searchItem: '',
    currentUser: null
  }
})

export default cache
