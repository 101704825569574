import { route } from 'quasar/wrappers';
import VueRouter from 'vue-router';
import routes from './routes';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

export default route(function({ Vue }) {
  Vue.use(VueRouter);

  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as is and change from quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  });

  Router.beforeResolve((to, from, next) => {
    // Vue.prototype.$q.loading.hide();

    if (to.path === '/') next({ name: 'productionManagement' });
    if (to.matched.some(record => record.meta.requiresAuth)) {
      Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().catch(() =>
        next({ name: 'signIn' })
      );
    }
    next();
  });

  return Router;
});
