import Vue from 'vue';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/',
    component: () => import('layouts/main-layout.vue'),
    children: [
      { path: '', component: () => import('pages/index.vue') },
      {
        path: 'config',
        name: 'config',
        component: () => import('pages/config/index.vue')
      },
      {
        path: 'users',
        name: 'userManagement',
        component: () => import('pages/user-management/index.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          if (Vue.prototype.$ability.cannot('view', to.name)) {
            next({ name: 'productionManagement' });
          } else {
            next();
          }
        }
      },
      {
        path: 'stocks',
        name: 'stockManagement',
        component: () => import('pages/stock-management/index.vue')
      },
      {
        path: 'production',
        name: 'productionManagement',
        component: () => import('pages/production-management/index.vue')
      },
      {
        path: 'tasks',
        name: 'taskManagement',
        component: () => import('pages/task-management/index.vue')
      },
      {
        path: 'containers',
        name: 'containerManagement',
        component: () => import('pages/container-management/index.vue')
      },
      {
        path: 'locations',
        name: 'locationManagement',
        component: () => import('pages/location-management/index.vue')
      },
      {
        path: 'parts',
        name: 'partManagement',
        component: () => import('pages/part-management/index.vue')
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    component: () => import('layouts/simple-layout.vue'),
    children: [
      {
        path: 'sign-in',
        name: 'signIn',
        component: () => import('pages/auth/auth-page.vue'),
        children: [
          {
            path: '/forgot-pass',
            name: 'forgotPass',
            component: () => import('pages/auth/forgot-pass-page.vue')
          },
          {
            path: '/change-pass',
            name: 'changePass',
            component: () => import('pages/auth/change-pass-page.vue'),
            props: true
          }
        ]
      }
    ]
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('pages/Error404.vue')
  }
];

export default routes;
