import { Component, Watch, Vue } from 'vue-property-decorator'

@Component
export default class CommonMixin extends Vue {
  loading: 0 | 1 = 0

  @Watch('loading')
  emitLoading(val: 0 | 1) {
    const loadingConfig = {
      customClass: this.isMainLayout,
      spinnerColor: 'primary',
      backgroundColor: 'bg',
      spinnerSize: 50
    }
    val ? this.$q.loading.show(loadingConfig) : this.$q.loading.hide()
  }

  get isMainLayout() {
    return this.$route.matched.some(route => route.meta.mainLayout)
      ? 'loading-main'
      : ''
  }
}
