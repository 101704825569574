export default {
  submit: 'Opslaan',
  cancel: 'Annuleer',
  close: 'Sluiten',
  footer: 'Copyright © Synadia. All Rights Reserved',
  welcome: 'Welkom',
  username: 'Gebruikersnaam',
  confirm: 'Bevestig',
  email: 'E-mailAdres',
  name: 'Volledige naam',
  alert: 'Alert',
  success: 'Success',
  description: 'Omschrijving',
  no_internet: 'Geen internet verbinding, probeer het nog eens?',
  location: 'Locatie',
  total: 'Totaal',
  error: 'Error',
  general: {
    yes: 'Ja',
    no: 'Nee',
    filter: {
      addNewFilter: '+ voeg filter toe',
      and: 'En',
      column: 'Kolom',
      condition: 'Conditie',
      filter: 'Filteren',
      title: 'Zoek filter',
      value: 'Waarde',
      where: 'Waar'
    },
    options: {
      userDetails: 'Huidige gebruiker',
      switchLanguage: 'Wijzig taal',
      dutch: 'Nederlands',
      english: 'Engels',
      changePassword: 'Wijzijg wachtwoord',
      signout: 'Uitloggen'
    },
    changePassword: {
      title: 'Wijzijg wachtwoord',
      cancel: 'Annuleer',
      save: 'Opslaan',
      success: 'Uw wachtwoord is succesvol gewijzigd',
      failed:
        'Er is iets mis gegaan tijdens het updaten van uw wachtwoord, probeer het nog eens.',
      failNewPassword: 'De nieuwe wachtwoorden komen niet overeen.',
      inputs: {
        oldPassword: 'Oud wachtwoord',
        newPassword: 'Nieuw wachtwoord',
        newPasswordCheck: 'Herhaal nieuw wachtwoord'
      }
    }
  },
  production: {
    loadMore: 'LAAD MEER',
    title: 'Productie beheer',
    search: 'Sales order',
    tabs: {
      orders: 'Orders',
      production: 'Huidige production (Orders)',
      batches: 'Batches'
    },
    assemblies: {
      table: {
        springBatchId: 'Spring batch ID',
        partId: 'Part ID',
        salesOrder: 'Sales order',
        partNumber: 'Part nummer',
        stationId: 'Station nummer',
        state: 'Status',
        materialAvailable: 'Materiaal beschikbaar',
        createdAt: 'Aangemaakt op',
        updatedAt: 'Aangepast op'
      }
    },
    batches: {
      table: {
        batch: 'Spring batch ID',
        state: 'Status',
        material: 'Materiaal beschikbaar',
        productionWeek: 'Productie Week',
        productionDate: 'Production Date',
        actions: "Actie's"
      },
      releaseBatch: {
        success: 'Het batchvrijgaveprocess is succesvol gestart',
        failed:
          'Er is iets misgegaan bij het vrijgeven van de batch. Probeer het opnieuw'
      }
    },
    errors: {
      title: 'Batch errors',
      table: {
        orderId: 'OrderId',
        partId: 'PartId',
        description: 'Omschrijving'
      }
    },
    orders: {
      cancel: {
        title: 'Waarschuwing',
        description: 'Weet u zeker dat u de order wilt cancellen?',
        success: 'Het order cancel process is succesvol gestart',
        failed:
          'Er is iet misgegaan tijdens het cancellen van de order, probeer het nog eens'
      },
      table: {
        salesOrder: 'Sales order ID',
        state: 'Status',
        material: 'Materiaal beschikbaar',
        productionWeek: 'Productie Week',
        productionDate: 'Production Date',
        actions: "Actie's"
      }
    },
    productionOrders: {
      table: {
        salesOrder: 'Sales order ID',
        state: 'Status',
        material: 'Materiaal beschikbaar',
        expectedWeek: 'Verwachte productie week',
        actions: "Actie's"
      }
    }
  },
  stocks: {
    title: 'Stock beheer',
    exportBtn: 'Exporteer naar .CSV',
    loadMore: 'LAAD MEER',
    search: 'Artikel nr.',
    table: {
      actions: 'Actions',
      article: 'Artikel nr.',
      qtyMain: 'Qty. main',
      containers: 'containers',
      qtyTemp: 'Aantal temporary',
      bundles: 'bundles',
      qtyTotal: 'Qty. totaal',
      qtyReserved: 'Qty. gereserveerd',
      qtyAvailable: 'Qty. beschikbaar',
      qtyAssembliesReleased: 'Qty. assemblies released',
      lastMonth: 'Afgelopen maand',
      lastQuarter: 'Afgelopen kwartaal',
      lastYear: 'Afeglopen jaar',
      minThreshold: 'Min. threshold'
    },
    editContainerQty: {
      title: 'Wijzig container aantallen',
      success:
        'Het container aantal is in een wachtrij geplaatst, het kan een aantal minuten duren voordat deze zijn verwerkt.',
      failed:
        'Er is iets mis gegaan bij het wijzigen van de container aantallen, probeer het nog eens.',
      btnCancel: 'Sluiten',
      btnContinue: 'Volgende',
      stepper: {
        step1: {
          title: 'Vul container ID in',
          input: 'Container ID',
          errorMessage:
            'De container ID is incorrect of heeft geen bundle aan zich gekoppeld.'
        },
        step2: {
          title: 'Wijzig container aantallen',
          inputCurrent: 'Huidige container aantallen',
          inputNew: 'Nieuwe container aantallen',
          inputNewHint:
            'Het kan een aantal minuten duren voordat de wijzigingen zichtbaar zijn.',
          btnBack: 'Terug',
          btnFinish: 'Opslaan'
        }
      }
    },
    editTempQty: {
      title: 'Wijzig bundel aantallen',
      success:
        'De bundel aantallen zijn in een wachtrij geplaatst, het kan een aantal minuten duren voordat deze zijn verwerkt.',
      failed:
        'Er is iets mis gegaan bij het wijzigen van de bundle aantallen, probeer het nog eens.',
      stepper: {
        step1: {
          title: 'Selecteer bundel',
          input: 'Selecteer bundel',
          errorMessage:
            'Het bundel ID is incorrect, check of het juiste bundel ID is ingevoerd en probeer het nog eens.',
          btnContinue: 'Volgende'
        },
        step2: {
          title: 'Wijzig bundel aantallen',
          inputCurrent: 'Huidige bundel aantallen',
          inputNew: 'Nieuwe bundel aantallen',
          inputNewHint:
            'Het kan een aantal minuten duren voordat de wijzigingen zichtbaar zijn.',
          btnBack: 'Terug',
          btnFinish: 'Opslaan'
        }
      }
    },
    editMinThreshold: {
      title: 'Edit minimun threshold',
      input: 'Minimum threshold',
      success: 'The minimum threshold is updated successfully.',
      failed:
        'Somethign went wrong while updating the minimum threshold, please try again.'
    }
  },
  containers: {
    title: 'Container beheer',
    createBtn: 'Creëer container',
    loadMore: 'LAAD MEER',
    search: 'Zoeken',
    table: {
      containerId: 'Container ID',
      partId: 'Part ID',
      quantity: 'Aantal',
      state: 'State',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      actions: 'Actions'
    },
    create: {
      title: 'Creëer container',
      success: 'De container is succevol toegevoegd.',
      failed:
        'Er is iets mis gegaan tijdens het aanmaken van de container, probeer het nog eens.',
      inputs: {
        containerId: 'Container ID'
      }
    },
    delete: {
      title: 'Verwijder container',
      description:
        'Weet u zeker dat je deze container wilt verwijderen? De container word permanent verwijderd.',
      success: 'De container is succesvol verwijderd.',
      failed:
        'Er is iet misgegaan tijdens het verwijderen van de container, probeer het nog eens.',
      delete: 'Delete'
    }
  },
  locations: {
    title: 'Locatie beheer',
    loadMore: 'LAAD MEER',
    createBtn: 'Creëer locatie',
    search: 'Zoeken',
    table: {
      locationId: 'Locatie ID',
      containerId: 'Container ID',
      column: 'Kolom',
      partId: 'Part ID',
      quantity: 'Aantal',
      rack: 'Rek',
      row: 'Rij',
      state: 'Status',
      type: 'Type',
      createdAt: 'Aangemaakt op',
      updatedAt: 'Laatst gewijzigd',
      actions: "Actie's"
    },
    create: {
      title: 'Creëer locatie',
      success: 'De locatie is succevol opgeslagen.',
      failed:
        'Er is iets misgegaan tijdens het aanmaken van de locatie, probeer het nog eens.',
      inputs: {
        column: 'Kolom',
        rack: 'Rek',
        row: 'Rij',
        type: 'Locatie type'
      }
    },
    delete: {
      title: 'Verwijder locatie',
      description:
        'Weet u zeker dat u deze locatie wilt verwijderen? De locatie word permanent verwijderd.',
      success: 'De locatie is succesvol verwijderd.',
      failed:
        'Er is iets misgegaan tijdens het verwijderen van de locatie, probeer het nog eens.',
      delete: 'Verwijder'
    }
  },
  parts: {
    title: 'Onderdeel beheer',
    loadMore: 'LAAD MEER',
    createBtn: 'Creëer Onderdeel',
    searchError:
      'Er is iets misgegaan tijdens het zoeken, probeer het nog eens.',
    table: {
      partNumber: 'Onderdeel nummer',
      description: 'Omschrijving',
      diameter: 'Diameter',
      type: 'Type',
      length: 'Lengte',
      active: 'Actief',
      state: 'Status',
      createdAt: 'Aangemaakt op',
      updatedAt: 'Laatst gewijzigd',
      actions: "Actie's"
    },
    create: {
      title: 'Creëer Onderdeel',
      success: 'Het Onderdeel is succesvol toegevoegd.',
      failed:
        'Er is iets misgegaan tijdens het aanmaken van het onderdeel, probeer het nog eens.',
      inputs: {
        partNumber: 'Onderdeel nummer',
        component: 'Component',
        description: 'Omnschrijving',
        length: 'Lengte',
        type: 'Type',
        diameter: 'Diameter'
      }
    },
    edit: {
      title: 'Wijzig Onderdeel',
      success: 'Het Onderdeel is succesvol gewijzigd.',
      failed:
        'Er is iets misgegaan tijdens het wijzigen van het onderdeel, probeer het nog eens.',
      inputs: {
        partNumber: 'Onderdeel nummer',
        component: 'Component',
        description: 'Omschrijving',
        length: 'Lengte',
        type: 'Type',
        state: 'Status',
        diameter: 'Diameter'
      }
    }
  },
  tasks: {
    edit: {
      title: 'Wijzig taak',
      cancel: 'Annuleer',
      save: 'Opslaan',
      success: 'Taak succesvol aangepast.',
      failed:
        'Er is iets misgegaan tijdens het wijzigen van de taak, probeer het nog eens.',
      inputs: {
        prio: 'Priotiteit)'
      }
    },
    loadMore: 'LAAD MEER',
    title: 'Taak beheer',
    search: 'Zoeken',
    table: {
      actions: "Actie's",
      partNumber: 'Onderdeel nummer',
      prio: 'Prioriteit',
      type: 'Type',
      qty: 'Aantal',
      state: 'Status',
      createdAt: 'Aangemaakt op',
      completedAt: 'Gedaan op'
    }
  },
  users: {
    title: 'Gebruikers beheer',
    createBtn: 'Creëer gebruiker',
    loadMore: 'LAAD MEER',
    table: {
      username: 'Gebruikersnaam',
      froups: 'Groep(len)',
      actions: "Actie's",
      createdAt: 'Aangemaakt op'
    },
    create: {
      title: 'Creëer gebruiker',
      cancel: 'Annuleer',
      save: 'Opslaan',
      success: 'Gebruiker is succesvol aangemaakt',
      failed:
        'Er is iets misgegaan tijd het aanmaken van de gebruiker, probeer het nog eens.',
      inputs: {
        email: 'E-mailadres',
        name: 'Naam',
        groups: 'Groep(len)'
      }
    },
    edit: {
      title: 'Wijzig gebruiker',
      cancel: 'Annuleer',
      save: 'Opslaan',
      success: 'Gebruiker succesvol toegevoegd.',
      failed:
        'Er is iets misgegeaan tijdens het wijzigen van de gebruiker, probeer het nog eens.',
      inputs: {
        email: 'E-mailadres',
        name: 'Naam',
        groups: 'Groep(len)'
      }
    },
    disable: {
      success: 'Gebruiker succevol geblokkeerd.',
      failed:
        'Er is iets misgegaan tijdens het blokkeren van de gebruiker, probeer het nog eens.',
      title: 'Blokkeer gebruiker',
      description: 'Weet u zeker dat u deze gebruiker wilt blokkeren?',
      cancel: 'Annuleer',
      delete: 'Blokkeer'
    },
    enable: {
      success: 'Gebruiker succevol geactiveerd.',
      failed:
        'Er is iets misgegaan tijdens het activeren van de gebruiker, probeer het nog eens.',
      title: 'Activeer gebruiker',
      description: 'Weet u zeker dat u deze gebruiker wilt activeren?',
      cancel: 'Annuleer',
      enable: 'Activeer'
    },
    delete: {
      success: 'De gebruiker is succevol verwijderd',
      failed:
        'Er is iets misgegaan tijdens het verwijderen van de gebruiker, probeer het nog eens.',
      title: 'Verwijder gebruiker',
      description:
        'Weet u zeker dat u deze gebruiker wilt verwijderen, de gebruiker word permanent verwijderd.',
      cancel: 'Annuleer',
      delete: 'Verwijder'
    }
  },
  config: {
    title: 'Config',
    table: {
      parameter: 'Parameter',
      value: 'Waarde'
    },
    edit: {
      title: 'Wijzig parameter',
      cancel: 'Annuleer',
      save: 'Opslaan',
      success: 'Succesvol opgeslagen',
      failed: 'Er is iets misgegaan, probeer het nog eens',
      inputs: {
        parameter: 'Parameter',
        value: 'Waarde'
      }
    }
  },
  auth: {
    sign_in: 'Inloggne',
    reset_pass: 'Reset wachtwoord',
    back_login: 'Terug naar login',
    resend_code: 'Verstuur code opnieuw',
    new_pass: 'Nieuw wachtwoord',
    confirm_pass: 'Bevestig wachtwoord',
    confirm_code: 'Verificatie code',
    change_pass: 'Wijzig wachtwoord',
    pass: 'Wachtwoord',
    email_phone: 'E-mailadres of telefoon nummer',
    save_creds: 'Opslaan'
    // code: "Code",
    // go_login: "Login with new account",
    // confirm: "Confirm Sign Up",
    // check_email: "Check your email for confirmation Code",
  },
  errors: {
    invalid_username_or_password: 'Gebruiker of wachtwoord is niet correct',
    no_negative_numbers: 'Het nummer moet groter zijn dan 0',
    required: 'Dit is een verplicht veld',
    to_long: 'Het veld is te lang',
    to_short: 'Het veld is te kort',
    whitespaces: 'Veld bevat alleen witruimtes',
    email: 'Veld moet een valide e-mailadres zijn.',
    pass_short: 'Wachtwoord is te kort',
    pass_number: 'Wachtwoord moet minstens 1 nummer bevatten',
    pass_uppercase: 'Wachtwoord moet minstens 1 hoofdletter bevatten',
    pass_lowercase: 'Wachtwoord moet minstens 1 kleine letter bevatten',
    code_mismatch:
      'De verstrekte code komt niet overeen met wat de server had verwacht',
    code_expired: 'Code is verlopen',
    not_authorized: 'De gebruiker is niet geautoriseerd',
    username_taken: 'De gebruikersnaam bestaat al'
  }
};
