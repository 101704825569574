import typeDefs from 'src/gql/client/types';
import resolvers from 'src/gql/client/resolvers';
import cache from 'src/gql/client/cache';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { ApolloClient, DefaultOptions } from 'apollo-client';
import Vue from 'vue';
import aws from '../aws-exports';

const config: any = {
  // @ts-ignore
  url: aws.userManagementAppsyncEndpoint,
  // @ts-ignore
  region: aws.region,
  auth: {
    // @ts-ignore
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () =>
      (await Vue.prototype.$Auth.currentSession()).getIdToken().getJwtToken()
  },
  offlineConfig: {
    keyPrefix: 'private'
  }
};

const options: any = {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    },
    connectToDevTools: true, // Remove this for production use
    disableOffline: true
  }
};

const awsClient: any = new AWSAppSyncClient(config, options);

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};
const apolloClient = new ApolloClient({
  cache,
  resolvers,
  typeDefs,
  defaultOptions,
  link: awsClient.link,
  connectToDevTools: true
});

export default apolloClient;
